import { makeStyles } from "@material-ui/core";
import topAreaBackground from '../../assets/images/top-area-background.png';
import centerAreaBackground from "../../assets/images/center-area-backround.png";
import contentBackground from '../../assets/images/content-background.png';
import contentBackgroundWithoutIcons from '../../assets/images/content-background-without-icons.png';
import blueBackground from '../../assets/images/blue-background.png';

const useStyles = makeStyles((theme) => ({
  index: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    float: "none",
  },
  topArea: {
    width: '100%',
    height: '50vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${topAreaBackground})`,
    paddingLeft: 122,
    paddingRight: 122,
    zIndex: 200,
  },
  titleArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: "10%",
    flex: 0.8,
    height: '100%',

  },
  titleText: {
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    fontFamily: "Bakso Sapi",
    fontSize: 54,
    ['@media (min-width: 1368px) and (max-width: 1440px)']: {
      fontSize: 42,
    }
  },
  descriptionText: {
    fontSize: 20,

  },
  moreInfoButton: {
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    width: 186,
    marginTop: 24,
  },
  mobileHeaderMoreInfoButton: {
    backgroundColor: "#399AD5",
    color: theme.palette.text.primary,
    width: 188,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    }
  },
  boxImageArea: {
    flex: 1,
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 1,
    marginTop: '70vh',
  },
  boxImage: {
    width: '40vw',
    height: '70vh',
  },
  sloganArea: {
    width: "100%",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${centerAreaBackground})`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: "30vh",
    paddingBottom: "20vh",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      marginTop: "4vh",
      paddingRight: 20,
      paddingTop: "30vh",
      paddingBottom: "12vh",
    }
  },
  mobileHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobileHeaderTitleArea: {
    display: 'flex',
    justifyContent: 'center',
    width: "80%",
  },
  mobileHeaderTitleText: {
    color: theme.palette.primary.main,
    fontFamily: "Bakso Sapi, Roboto",
    textAlign: 'center'
  },
  howItWorksButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
  },
  mobileHeaderSubtitleArea: {
    width: "80%",
    marginTop: 30,
  },
  mobileHeaderSubtitleText: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontFamily: "Roboto",
    fontWeight: 500,
  },
  mobileHeaderKnowMoreButtonArea: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center'
  },
  sloganQuoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    }
  },
  sloganText: {
    color: "#7C8999",
    fontFamily: 'Frenchpress, Roboto',
    fontWeight: 400,
    textAlign: 'center',
    marginTop: 16,
    WebkitTextStroke: `1px #7C8999`,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
    }
  },
  sloganDescriptionText: {
    color: theme.palette.text.secondary,
    marginTop: 4,
    textAlign: 'center',
    fontFamily: 'Dosis, Roboto',
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body1.fontSize,
    }
  },
  openQuotesIcon: {
    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
    }
  },
  closeQuotesIcon: {
    marginTop: 32,
    [theme.breakpoints.down("sm")]: {
      width: 24,
      height: 24,
    }
  },
  howItWorksArea: {
    marginTop: "-20vh",
    width: "100%",
    backgroundImage: `url(${contentBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    paddingTop: "30vh",
    paddingBottom: "10vh",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${contentBackgroundWithoutIcons})`,
      height: '100%',
      marginTop: '-15vh',
      paddingTop: '25vh',
      paddingBottom: "20vh"
    }
  },

  howItWorksCarouselContainer: {
    width: '100%',
    marginTop: 48,
    marginBottom: 48,
    display: 'flex',
    justifyContent: 'center',
  },

  howItWorksTitleText: {
    fontFamily: 'Frenchpress, Roboto',
    fontWeight: 400,
    letterSpacing: "4%",
    WebkitTextStroke: `1px ${theme.palette.secondary.main}`,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize
    }
  },

  howItWorksMoreInfoButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 250,
    fontSize: 22,
    //outline: `4px solid rgba(88, 190, 190, .5)`,
  },
  kitsArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
    zIndex: 1,
    paddingTop: "15vh",
    paddingBottom: "30vh",
    marginTop: "-5vh",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-20vh",
      paddingTop: "20vh",
      paddingBottom: "25vh",
    }
  },
  kitsTitleArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  kitsTitleText: {
    color: "#7C8999",
    fontFamily: "Frenchpress, Roboto",
    letterSpacing: "4%",
    fontWeight: 400,
    textAlign: 'center',
    WebkitTextStroke: '1px',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
    }
  },
  kitsTitleTextHighlight: {
    color: theme.palette.secondary.main,
  },
  kitsSubtitleText: {
    color: theme.palette.text.secondary,
    fontFamily: "Dosis, Roboto",
    fontWeight: 700,
    width: '80%',
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.body1.fontSize,
      marginTop: 20,
    }
  },
  kitsDisplayerArea: {
    marginTop: 70,
    display: 'flex',
    width: '100%',
  },
  FAQSArea: {
    height: "100%",
    marginTop: '-10vh',
    backgroundImage: `url(${blueBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20vh',
    paddingBottom: '30vh',
    zIndex: 100,
    alignItems: 'center',
    marginBottom: "-15vh",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-20vh",
      marginTop: "-12vh",
    }
  },
  FAQSContainer: {
    width: "80%",
    display: 'flex',
    marginTop: 32,
  },
  FAQSAreaTitleText: {
    color: theme.palette.text.primary,
    fontFamily: "Frenchpress, Roboto",
    letterSpacing: "4%",
    fontWeight: 400,
    textAlign: 'center',
    WebkitTextStroke: `1px ${theme.palette.text.primary}`,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h3.fontSize,
    }
  },
  subscribeButtonArea: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    }
  },
  subscribeButton: {
    width: 232,
    height: 60,
    backgroundColor: "#F2AF2F",
    color: theme.palette.text.primary,
    fontSize: 24,
    marginLeft: 20,
    "&:hover": {
      backgroundColor: "#F2AF2F"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 40,
    }
  },
  buySingleBoxButton: {
    width: 232,
    height: 60,
    backgroundColor: "#57BEBF",
    color: theme.palette.text.primary,
    fontSize: 24,
    marginRight: 20,
    "&:hover": {
      backgroundColor: "#57BEBF"
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    }
  }

}));

export default useStyles;