import { Button, Grow, Hidden, Slide, Typography } from "@material-ui/core";
import { ArrowRightAlt as ArrowIcon } from "@material-ui/icons";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import closeQuotesIcon from "../assets/icons/close-quotes-icon.png";
import openQuotesIcon from "../assets/icons/open-quotes-icon.png";
import boxImage from "../assets/images/box-image.png";
import FAQContainer from "../components/FAQ-container";
import HowItWorks from "../components/how-it-works/HowItWorks";
import PlansDisplayer from "../components/plans-displayer/PlansDisplayer";
import { SINGLE_SHOP_WEBSITE_URL } from "../constants/single-shop-website-url";
import {
  navigateOnHome,
  navigateToLink,
} from "../helpers/navigation-helper/navigation-helper";
import DefaultLayout from "../layouts/default-layout";
import useStyles from "../styles/index/styles";

function Index() {
  const classes = useStyles();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  if (!pageLoaded) return null;

  return (
    <DefaultLayout>
      <div className={classes.index} id="home">
        <Helmet>
          <title>Smart Baby Box</title>
        </Helmet>
        <Hidden smDown>
          <div className={classes.topArea}>
            <Slide in={true} timeout={1000} direction="right">
              <div className={classes.titleArea}>
                <Typography className={classes.titleText}>
                  O brinquedo certo para a idade certa!
                </Typography>
                <Typography variant="h6" className={classes.descriptionText}>
                  Receba em sua casa a cada mês brinquedos escolhidos por
                  especialistas, adequados ao desenvolvimento do seu bebê em
                  cada fase dos primeiros anos de vida!
                </Typography>
                <Button
                  variant="contained"
                  className={classes.moreInfoButton}
                  endIcon={<ArrowIcon />}
                  onClick={() => navigateOnHome("como-funciona")}
                >
                  Saiba Mais
                </Button>
              </div>
            </Slide>
            <Grow in={true} timeout={1000}>
              <div className={classes.boxImageArea}>
                <img
                  src={boxImage}
                  alt="Imagem da caixa de brinquedos"
                  className={classes.boxImage}
                />
              </div>
            </Grow>
          </div>
        </Hidden>

        <div className={classes.sloganArea}>
          <Hidden smUp>
            <div className={classes.mobileHeader}>
              <div className={classes.mobileHeaderTitleArea}>
                <Typography
                  variant="h4"
                  className={classes.mobileHeaderTitleText}
                >
                  O brinquedo certo para a idade certa!
                </Typography>
              </div>
              <div className={classes.mobileHeaderSubtitleArea}>
                <Typography
                  variant="body1"
                  className={classes.mobileHeaderSubtitleText}
                >
                  Receba em sua casa a cada mês brinquedos escolhidos por
                  especialistas, adequados ao desenvolvimento do seu bebê em
                  cada fase dos primeiros ano de vida!
                </Typography>
              </div>
              <div className={classes.mobileHeaderKnowMoreButtonArea}>
                <Button
                  variant="contained"
                  className={classes.mobileHeaderMoreInfoButton}
                  endIcon={<ArrowIcon />}
                  onClick={() => navigateOnHome("como-funciona")}
                >
                  Saiba mais
                </Button>
              </div>
            </div>
          </Hidden>
          <div className={classes.sloganQuoteContainer}>
            <img
              src={openQuotesIcon}
              alt="Abre aspas"
              className={classes.openQuotesIcon}
            />
            <Typography variant="h1" className={classes.sloganText}>
              inspirado nos melhores!
            </Typography>
            <Typography variant="h5" className={classes.sloganDescriptionText}>
              Nossos brinquedos são inspirados nas pedagogias Montessori,
              Waldorf e Pikler
            </Typography>
            <img
              src={closeQuotesIcon}
              alt="Fecha aspas"
              className={classes.closeQuotesIcon}
            />
          </div>
        </div>
        <div className={classes.howItWorksArea} id="como-funciona">
          <Typography
            variant="h1"
            color="secondary"
            className={classes.howItWorksTitleText}
          >
            como funciona?
          </Typography>
          <Grow in={true} timeout={1000}>
            <div className={classes.howItWorksCarouselContainer}>
              <HowItWorks />
            </div>
          </Grow>
          <div className={classes.howItWorksButtonsContainer}>
            <Button
              variant="contained"
              className={classes.subscribeButton}
              onClick={() => navigate("/planos")}
            >
              Assine Já
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.howItWorksMoreInfoButton}
              endIcon={<ArrowIcon />}
              onClick={() => navigateOnHome("dentro")}
            >
              Saiba Mais
            </Button>
          </div>
        </div>
        <div className={classes.kitsArea} id="dentro">
          <div className={classes.kitsTitleArea}>
            <Typography variant="h1" className={classes.kitsTitleText}>
              todo mês uma{" "}
              <span className={classes.kitsTitleTextHighlight}>
                box diferente
              </span>
            </Typography>
            <Typography variant="h5" className={classes.kitsSubtitleText}>
              um verdadeiro presente a cada mesversário para que seu bebê
              exercite a criatividade e confiança em si mesmo
            </Typography>
          </div>
          <Grow in={true} timeout={1000}>
            <div className={classes.kitsDisplayerArea}>
              <PlansDisplayer />
            </div>
          </Grow>
          <div className={classes.subscribeButtonArea}>
            <Button
              variant="contained"
              className={classes.buySingleBoxButton}
              onClick={() => navigateToLink(SINGLE_SHOP_WEBSITE_URL)}
            >
              Avulsos
            </Button>
            <Button
              variant="contained"
              className={classes.subscribeButton}
              onClick={() => navigate("/planos")}
            >
              Assine Já
            </Button>
          </div>
        </div>
        <div className={classes.FAQSArea} id="perguntas-frequentes">
          <div>
            <Typography variant="h1" className={classes.FAQSAreaTitleText}>
              perguntas frequentes
            </Typography>
          </div>
          <div className={classes.FAQSContainer}>
            <FAQContainer />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Index;
