export interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: "O valor da mensalidade muda ao longo da assinatura? ",
    answer: "Não! A mensalidade é fixa do início ao fim. É possível entrar até o mês 23, mas o valor se repete pelo número de meses restantes até o mês 24.",
  },
  {
    question: "E se eu quiser cancelar?",
    answer: "Você pode cancelar a qualquer momento, é só solicitar com 40 dias de antecedência. A Box na sequência será faturada, e então faremos o cancelamento. Solicite enviando email para atendimento@smartbabybox.com.br com o assunto \"quero cancelar minha assinatura\" e nome e email cadastrados na plataforma de pagamento.",
  },
  {
    question: "Meu bebê já tem dois meses, mas quero receber a Box 1, como faço? ",
    answer: "Entre em contato conosco pelo WhatsApp (11) 95300-3557. Nossa equipe vai te orientar com as melhores condições.",
  },
  {
    question: "Meu bebê tem 9 meses, quero entrar na assinatura mas também quero algumas Boxes anteriores, como a 4 e a 6, como posso fazer?",
    answer: "Entre em contato conosco pelo WhatsApp (11) 95300-3557. Nossa equipe vai te orientar com as melhores condições.",
  },
  {
    question: "Comprei uma Box e não gostei. Como devolver? ",
    answer: "Segundo a lei para compras online, você pode devolver até 7 dias após o recebimento da compra. Mande email solicitando a devolução para atendimento@smartbabybox.com.br. Daí encontraremos o ponto de devolução mais perto da sua casa e enviaremos as orientações para a postagem de volta. Para isso você precisará guardar a embalagem e enviar a Box do jeito que recebeu, na mesma caixa."
  }
]

export default faqs;